const config = {
    development: {
        api: "http://localhost:3332",
        namespace: "scribus-dev"
    }, 
    production: {
        api: "https://api.scribus.ro",
        namespace:"scribus-prod"
    }
}

export default config[process.env['NODE_ENV']]