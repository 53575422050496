import React from 'react'
import { Grid, Paper, makeStyles, Typography, List, ListItem, Divider, Button, 
    Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core'
import FunkyInput from '../../components/FunkyInput'
import { FaTimes, FaEdit, FaPlus, FaCheck } from 'react-icons/fa'
import AddUserModal from './AddUserModal'
import { useQuery, useMutation } from '@apollo/client'
import { MUTATE_COMPANY, MUTATE_USER , REMOVE_USER } from './_graphql'

const fields = [
    { name: 'name', label: "denumire", xs: 12, sm: 12 },
    { name: 'address', label: "adresă", xs: 9, sm: 9 },
    { name: 'city', label: "localitate", xs: 3, sm: 3 },
    { name: 'cui', label: "cui/cif", xs: 6, sm: 6 },
    { name: 'regcom', label: "nr.Reg.Com.", xs: 6, sm: 6 },
    { name: 'phone', label: "telefon", xs: 12, sm: 3 },
    { name: 'fax', label: "fax", xs: 12, sm: 3 },
    { name: 'email', label: "email", xs: 12, sm: 6 },
    { name: 'www', label: "site web", xs: 12, sm: 4 },
    { name: 'obs', label: "observații", xs: 12, sm: 8 },
]

const useStyles = makeStyles(t => ({
    root: { flexGrow: 1 },
    paper: { padding: t.spacing(1), margin: t.spacing(1), border: '1px dotted navy' }
}))

const Details = props => {
    const classes = useStyles()
    const [item, setItem] = React.useState()
    const [err, setErr] = React.useState()
    const [cTouched, setCTouched] = React.useState(false)
    const [ userModal, setUserModal ] = React.useState()
    const [ removeUserModal, setRemoveUserModal ] = React.useState()
    const [ updateCompany, dispatchMutation ] = useMutation(MUTATE_COMPANY)
    const [ mutateUser, dispatchMUser ] = useMutation(MUTATE_USER)
    const [ removeUser, dispatchRemoveMUser ] = useMutation(REMOVE_USER)

    const inputHandler = name => ev => { setItem({ ...item, [name]: ev.target.value }); setCTouched(true) }

    React.useEffect(() => {
        if (props && props.data &&!cTouched) {
            setErr()
            setItem(props.data)
        }
    }, [props])

    const initializeCompany = () => {
        let ni = {
            company:  { name: "", cui: "", regcom: "", address:"", city:"" },
            users: []
         }
         setItem(ni)
    }

    const submitCompany = async () => {

        let ni = {...item}
        // console.log("item to submit:", ni)
        delete(ni.users)
        let promise, err;
        try {
            promise = await updateCompany({variables: {...ni}})
        } catch (error) {
            err = error
        } finally {
            if ( err ) { console.log(err); setErr(err) }
            else {
                setItem({...item , ...promise.data.company})
            }
            setCTouched(false)
            props.onChanges()
        }

    }

    const initializeUserModal = () => {
        setUserModal({ companyID: item.id, name: "", username: "", email:"", password: "", role: ""})
    }
    const editUserModal = ev => () => { setUserModal(ev) }

    const userModalCloseHandler = async ev => {
        // console.log("Ev:", ev)
        let promise;
       if ( !ev ) { setUserModal() } else {
            try {
                promise = await mutateUser({variables: ev})
            } catch (error) {
                promise = {err: error}
            } finally {
                console.log("promise: ", promise)
                if ( promise.err ) {
                    setErr(promise.err)
                    setUserModal()
                } else {
                    let ni = {...item}
                    if ( ev.id ) {
                        ni.users[ev.index] = promise.data.user
                    } else {
                        ni.users = [...ni.users, promise.data.user]
                    }
                    setItem(ni)
                    setUserModal()
                }
            }
       }
    }

    const removeUserHandler = async () => {
        // console.log("remove user modal: ", removeUserModal.id)
        let promise;
        try {
            promise = await removeUser({variables: {id: removeUserModal.id }})
        } catch (error) {
            promise = { err: error }
        } finally {
            //remvove from db then...
            let ni = {...item}
            ni.users.splice(removeUserModal.index, 1)
            setItem(ni); setRemoveUserModal()
        }
       

    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {err && <pre>{JSON.stringify(err, null, 4)}</pre>}
                {item ? (
                    <React.Fragment>
                        <Grid container spacing={1} >
                            <Grid item xs = {12} style = {{background: "#2196f32b", marginBottom: '5px'}}>
                                <Grid container justify = "space-between" alignItems = "center">
                                    
                                    <Typography variant = "body2" color = "primary" component = "div">
                                        &nbsp;&nbsp;<strong>DATE COMPANIE</strong>
                                    </Typography>
                                    
                                    {item && item && item.id && !cTouched
                                        ? (
                                            <Button size = "small" color = "primary" variant = "contained" onClick = {initializeCompany} >
                                                <FaPlus style = {{height:'1rem', marginRight: '10px'}}/>
                                                ADAUGĂ COMPANIE
                                            </Button>
                                        ) : (
                                            <Button size = "small" color = "secondary" variant = "contained" onClick = {submitCompany} >
                                                <FaCheck style = {{height:'1rem', marginRight: '10px'}}/>
                                                CONFIRMĂ
                                            </Button>    
                                        )
                                    }

                                </Grid>
                            </Grid>
                           
                            {fields.map((elem, index) => (
                                <Grid item xs={elem.xs} sm={elem.sm} key={`input-${index}`}>
                                    <FunkyInput
                                        name={elem.name}
                                        label={elem.label}
                                        value={item[elem.name]}
                                        onChange={inputHandler(elem.name)} />
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ) : <Typography variant="h4" color="primary" align="center">ALEGE O FIRMĂ</Typography>}
            </Paper>
            <Paper className={classes.paper}>
                {item ? (
                    
                    <div style = {{height: "35vh"}}>
                    <Grid container spacing={1}>
                       <Grid item xs = {12} style = {{background: "#2196f32b"}}>
                                <Grid container justify = "space-between" alignItems = "center"  >
                                    
                                    <Typography variant = "body2" color = "primary" component = "div">
                                        &nbsp;&nbsp;<strong>UTILIZATORI</strong>
                                    </Typography>
                                     <Button size = "small" color = "primary" variant = "contained" 
                                        disabled = {item && item.company && !item.company.id}
                                        onClick = {initializeUserModal}    
                                    >
                                        <FaPlus style = {{height:'1rem', marginRight: '10px'}}/>
                                        ADAUGĂ UTILIZATOR
                                    </Button>
                                </Grid>
                            </Grid>
                       
                            <ListItem>
                            <Grid container justify = "flex-start" style = {{borderBottom: "1px dashed lightgrey"}}>
                                        <Grid item xs = {3}><Typography variant = "caption" color = "primary"><strong>&nbsp;NUME</strong></Typography></Grid>
                                        <Grid item xs = {2}><Typography variant = "caption" color = "primary"><strong>USERNAME</strong></Typography></Grid>
                                        <Grid item xs = {3}><Typography variant = "caption" color = "primary"><strong>EMAIL</strong></Typography></Grid>
                                        <Grid item xs = {2}><Typography variant = "caption" color = "primary"><strong>ROLE</strong></Typography></Grid>
                                    </Grid>
                            </ListItem>
                            <Divider/>
                            <List component = "nav" style = {{width: '100%',overflow: "auto", height: "25vh" }}>
                            {item.users.map((elem, index) => (
                                <span  key = {`u-${index}`}>
                                <ListItem button component="a">
                                    <Grid container justify = "flex-start">
                                        <Grid item xs = {3}><small>{elem.name}</small></Grid>
                                        <Grid item xs = {2}><small>{elem.username}</small></Grid>
                                        <Grid item xs = {3}><small>{elem.email}</small></Grid>
                                        <Grid item xs = {1}><small>{elem.role}</small></Grid>  
                                        <Grid item xs = {3} align = "right">                                          
                                            <Button 
                                                disabled = {elem.entries && elem.entries.length > 0 }
                                                color = {elem.entries && elem.entries.length > 0 ? "default":"secondary" }
                                                onClick = { () => setRemoveUserModal({...elem, index: index}) }
                                            >
                                                <FaTimes/>
                                            </Button>
                                            <Button> 
                                                <FaEdit style = {{color: "green"}} onClick = {editUserModal({...elem, index:index})}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                </ListItem>
                                <Divider/>
                                </span>
                            ))}
                            </List>
                       </Grid>
                    </div>
                ) : (
                    <div align = "center">
                        <Button size = "small" color = "primary" variant = "contained" onClick = {initializeCompany} >
                            <FaPlus style = {{height:'1rem', marginRight: '10px'}}/>
                            ADAUGĂ COMPANIE
                        </Button>
                    </div>

                )}
            </Paper>
            {userModal && (
                <AddUserModal data = {userModal} onClose = {userModalCloseHandler} />
            )}
            

            <Dialog open = {Boolean(removeUserModal)} onClose = {()=>{}} >
                <DialogTitle>Eliminare utilizator</DialogTitle>
                <DialogContent>
                    Veți șterge definitiv utilizatorul {removeUserModal && removeUserModal.name}
                </DialogContent>
                <DialogActions>
                    <Button variant = "contained" color = "primary" onClick = { removeUserHandler }>CONFIRMĂ</Button>
                    <Button variant = "contained" color = "secondary" onClick = { () => setRemoveUserModal() }>RENUNȚĂ</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Details
